import React from 'react';
import { useStyles } from './Style';
import { Container, Grid, Typography, Box, } from '@mui/material'
import Slider from "react-slick";

const WallOfLove = () => {
    const classes = useStyles();
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 4000,
        cssEase: "linear",
    };
    return <div >
        <Box className={classes.wall_of_love}>

            <Container maxWidth="lg" sx={{ margin: { md: '100px auto', xs: '50px auto' }, }}>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                >
                    <Grid item xl="4" md="4" xs="12" >
                        <Typography sx={{ fontWeight: "bold", fontSize: { md: "48px", xs: "31px" }, lineHeight: { md: "70px", xs: '48px' }, color: "#1B1B1B" }}>
                        <img src={require("../../../Assets/home/cutie.png")} alt="image_" className='banner_image' />
                        </Typography>
            
                        {/* <Typography sx={{ px: '20px', fontWeight: "500", fontSize: { md: "20px", xs: '16px' }, lineHeight: "30px", color: "#1B1B1B" }}>
                            - Sonika Chopra
                        </Typography> */}
                    </Grid>
                    <Grid item xl="6" md="6" xs="12" >
                    <Typography sx={{ fontWeight: "bold", fontSize: { md: "48px", xs: "31px" }, lineHeight: { md: "70px", xs: '48px' }, color: "#1B1B1B" }}>
                            Wall of L
                            <img src={require("../../../Assets/home/heart.png")} alt="image_" />
                            ve
                        </Typography>
                        <Slider {...settings}>
                            {Embed.map((item, i) => (
                                <>
                                    {item.iframe}
                                </>
                            ))}
                        </Slider>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </div >;
};

export default WallOfLove;

const Embed = [
    // { iframe: <img src={require("../../../Assets/home/cutie.png")} alt="image_" className='banner_image' /> },
    { iframe: <Typography sx={{ fontWeight: "500", fontSize: { md: "20px", xs: '16px' }, lineHeight: "180%", color: "#1B1B1B", margin: "25px" }}>
    "As a parent, I couldn't be happier with the transformation I've seen in my child since enrolling them in this after-school program. Not only have they become more confident and independent, but they've also developed essential skills like critical thinking and problem-solving. This program truly empowers children to thrive in the 21st century!"
    </Typography> },
    { iframe: <Typography sx={{ fontWeight: "500", fontSize: { md: "20px", xs: '16px' }, lineHeight: "180%", color: "#1B1B1B", margin: "25px" }}>
    "My son used to be shy and hesitant to speak up in class, but after attending this after-school program, he's like a different child. He's now a confident speaker, always eager to share his ideas and thoughts. I'm amazed at how much he's grown in such a short time. Thank you for providing such a nurturing and empowering environment for our kids."
    </Typography> },
    { iframe: <Typography sx={{ fontWeight: "500", fontSize: { md: "20px", xs: '16px' }, lineHeight: "180%", color: "#1B1B1B", margin: "25px" }}>
    "I'm incredibly impressed with the holistic approach of this after-school program. Not only are my children learning academic subjects, but they're also developing important life skills like teamwork, communication, and creativity. The instructors are passionate and dedicated, and it's evident that they truly care about the success and well-being of each child. I highly recommend this program to any parent looking to give their child a competitive edge in today's world."
    </Typography> },
    // { iframe: <iframe width="100%" height="315" src="https://www.youtube.com/embed/qmUPB7f8J7o?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> },
    // { iframe: <iframe width="100%" height="315" src="https://www.youtube.com/embed/txSQTz9vOCs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> },
    // { iframe: <iframe width="100%" height="315" src="https://www.youtube.com/embed/epTX02VUWCo?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> },
    // { iframe: <iframe width="100%" height="315" src="https://www.youtube.com/embed/QX2zS_ue81U?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> },
    // { iframe: <iframe width="100%" height="315" src="https://www.youtube.com/embed/0wES7--YiTg?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> },
    // { iframe: <iframe width="100%" height="315" src="https://www.youtube.com/embed/mGJDCm6vDPU?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> },
]